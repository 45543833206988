<template>
  <div class="main-document">
    <div class="d-flex main-document-tabs ml-6 my-1">
      <v-tabs
        v-if="activeTabs.length > 1"
        v-model="activeTabIndex"
        :background-color="themeController.bannerColor"
        slider-color="primary"
        color="unset"
        class="ml-2 my-2"
        optional
      >
        <v-tab
          v-for="(t, index) in activeTabs"
          :key="getTabKey(t)"
        >
          <document-tab-header :tab="activeTabs[index]" />
        </v-tab>
      </v-tabs>
    </div>
    <div class="sub-document">
      <keep-alive>
        <component
          v-if="loaded && activeTab"
          :tab="activeTab"
          :is="activeTab.documentType"
          :key="getTabKey(activeTab)"
          @document-ready="setDocumentReady"
          @document-comment="displayDocumentComment"
          @document-reset="resetDocumentState"
          @document-saved="setDocumentSaved"
          @document-can-save="setDocumentCanSave"
          @document-title-change="setDocumentTitle"
          @document-changed="setDocumentChanged"
          v-keep-scroll-position
        />
      </keep-alive>
    </div>
    <v-dialog
      v-model="recentsController.displayPopUp[documentId]"
      max-width=290
      @click:outside="recentsController.closePopUp(documentId)"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>{{documentTitle}} {{$t('t.DocumentNotSavedDiscardChanges')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="recentsController.proceedPendingTabChanges({ documentId: accountId })">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="recentsController.closePopUp(documentId)"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import recentsController from '../recentsController'
import Vue from 'vue'
import themeController from '../themeController'

export default {
  components: {
    AccountContacts: () => import('@/pages/account-contacts/account-contact-infos'),
    Administration: () => import('@/pages/settings/administration-infos'),
    Users: () => import('@/pages/users/user-infos'),
    AgingScenarios: () => import('@/pages/aging-scenarios/aging-scenario-infos'),
    BusinessDivisions: () => import('@/pages/business-divisions/business-division-infos'),
    DocumentTabHeader: () => import('@/components/document-tab-header'),
    EscalationProtocols: () => import('@/pages/escalation-protocols/escalation-protocol-infos'),
    LabelGroups: () => import('@/pages/labels/label-group-infos'),
    Labels: () => import('@/pages/labels/label-infos'),
    NewImport: () => import('@/pages/import/new-import-infos'),
    MessageUnassignedDetail: () => import('@/pages/messages/message-unassigned-detail'),
    Processes: () => import('@/pages/processes/process-infos'),
    Processing: () => import('@/pages/settings/processing-infos'),
    Providers: () => import('@/pages/providers/provider-infos'),
    Topics: () => import('@/pages/topics/topic-infos'),
    WorkQueues: () => import('@/pages/work-queues/work-queue-infos'),
    Hierarchy: () => import('@/pages/business-divisions/hierarchy')
  },
  watch: {
    document (d) {
      this.setModule(d)
      this.setRecentControllerScope(d.id)
    },
    'recentsController.current': {
      deep: true,
      immediate: true,
      async handler (v) {
        this.current = Object.assign({}, this.current, v)
      }
    },
    'activeTab.documentId' () {
      this.loaded = false
      this.$nextTick(() => {
        this.loaded = true
      })
    }
  },
  computed: {
    activeTabs: {
      get () {
        if (!this.document) { return this.tabs }
        const tabs = this.tabs.concat(this.current?.tabs || [])
        return Vue.observable(tabs)
      }
    },
    activeTab () {
      return this.activeTabs[this.activeTabIndex]
    },
    activeTabIndex: {
      get () {
        return this.internalActiveTabIndex
      },
      set (i) {
        if (typeof i !== 'undefined') {
          this.internalActiveTabIndex = i
        }
      }
    },
    documentId () {
      return this.document?.id
    },
    documentTitle () {
      return recentsController.hold.oldTab?.title
    }
  },
  data () {
    return {
      current: null,
      internalActiveTabIndex: 0,
      loaded: true,
      pagePath: this.$route.path,
      recentsController: recentsController,
      themeController: themeController
    }
  },
  methods: {
    setModule (d) {
      if (d) {
        this.setRecentControllerScope(d.id)
        this.$store.commit('setModule', { data: d })
      }
    },
    displayDocumentComment (data) {
      data.tab.showComment = data.value
    },
    getTabKey (t) {
      return t.documentType + this.pagePath
    },
    setDocumentSaved (tab) {
      tab.isNew = false
    },
    setDocumentReady (tab) {
      tab.isLoaded = true
    },
    resetDocumentState (tab) {
      tab.isLoaded = false
      tab.isPristine = true
    },
    setRecentControllerScope (accountId) {
      recentsController.scope = {
        documentType: this.document.type,
        documentId: accountId,
        staticTabs: this.tabs
      }
    },
    setDocumentCanSave (data) {
      data.tab.canSave = data.value
    },
    setDocumentTitle (data) {
      data.tab.title = data.value
    },
    setDocumentChanged (tab) {
      tab.isPristine = false
    }
  },
  props: {
    document: [Object, String],
    tabs: Array,
    documentHeaderComponentName: String
  }
}
</script>

<style lang="stylus" scoped>
.main-document
  display grid
  grid-template-areas 'tab chat' 'doc chat'
  grid-template-columns 1fr min-content
  grid-template-rows min-content 1fr
  flex-grow 1
  overflow hidden

.main-document-tabs
  grid-area tab

.chat
  grid-area chat
  width 500px
  background-color #ffffff

.sub-document
  display flex
  grid-area doc
  flex-grow 1
  overflow hidden
  flex-direction row
</style>
